import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/service/database.service';
import { AppComponent } from 'src/app/app.component';
 import { FormControl , ReactiveFormsModule, NgForm, FormsModule  } from '@angular/forms';
import { empty } from 'rxjs';


@Component({
  selector: 'app-lead-details',
  templateUrl: './lead-details.component.html',
  styleUrls: ['./lead-details.component.css']
})
export class LeadDetailsComponent implements OnInit {
  name = new FormControl('');
  lead_id = new FormControl('');
  email = new FormControl('');
  phone = new FormControl('');
  form_name = new FormControl('');
  country = new FormControl('');
  service = new FormControl('');
  budget = new FormControl('');
  description = new FormControl('');
  lead_status = new FormControl('');
  lead_comments = new FormControl('');
  id: number;
  isUser : boolean = true;

  showLoader : boolean = false;

  lead_dropdown = [
    { id: 1, label: "Pending" },
    { id: 2, label: "Invalid Number" },
    { id: 3, label: "Not Answering" },
    { id: 4, label: "Voice Mail" },
    { id: 5, label: "Left a voice mail" },
    { id: 6, label: "Answering Machine" },
    { id: 7, label: "Busy" },
    { id: 8, label: "Not Interested" },
    { id: 9, label: "Sale Made" },
    { id: 10, label: "Call Back" }
]
  constructor(private route: ActivatedRoute,private router: Router,private _database : DatabaseService,private AppComponent : AppComponent) { 
     this.AppComponent.setTitle("Lead Single Page");
    // this.AppComponent.getTitle();
  }

  successMsg: any = "";
  successShow: boolean = false;
  alertClass: any = "";

  SuccessBox(msg) {
    console.log("sucess message>>>>>",msg);
    this.successMsg = msg;
    this.alertClass = "success";
    this.successShow = true;
  }

  FailedBox(msg) {
    this.successMsg = msg;
    this.alertClass = "danger";
    this.successShow = true;
  }

  closeSuccess() {
    this.successShow = false;
  }

  changeLeadViewStatus(lead_id){
    console.log("changeLeadViewStatus>>>>",lead_id);
    this._database.checkAndUpdateLeadViewStatus(this.id).then(data => {
      //console.log(data);
      return data;

    });
  }
  ngOnInit(): void {
    console.log("I am in lead detail page on init");
    this.showLoader = true;
    this.id = this.route.snapshot.params['id'];

    //this.changeLeadViewStatus(this.id);

    const userDD = JSON.parse(localStorage.getItem('dataUser'))
    if(userDD['Role'][0] == "ROLE_ADMIN"){
      this.isUser = false;
    } else {
      this.changeLeadViewStatus(this.id);
    }
     //this.userType = userDD['Role'][0];
    //console.log("userType>>>>>>>>>>>",userType)

    console.log(this.id);
   //getLeadDetail
   this._database.getLeadDetail(this.id).then(data => {
    //console.log(data);
    if(data != null){
      console.log("I am inside then clause getLeadDetails");
      console.log(data);
      this.lead_id = data['id'];
      this.name = data['name'];
      this.email = data['email'];
      this.phone = data['phone'];
      this.form_name = data['form_name'];
      this.country = data['country'];
      this.service = data['service'];
      this.budget = data['budget'];
      this.description = data['description'];
      this.lead_status = data['lead_status'];
      this.lead_comments = data['lead_comments'];
      //this.userType = userType;
    }
    else{
      // this.FailedBox(data['response'])
      // this.showLoader = false;
    }
  }).catch(err => {
    this.showLoader = false;
  });

    
  }

  url: any = "";
  selecetdFile = '';
  selectedFileName = '';
  showSubmit: boolean = true;

  updateLeadStatus(form: NgForm) {
    this.showLoader = true;
    event.preventDefault()
    const lead_id = form.value.lead_id;
    const Name = form.value.lead_status;
    const lead_comments = form.value.lead_comments;
    console.log("lead_status>>>>>",Name);
    //return;
    if (Name === null && empty(Name) || lead_comments === null && empty(lead_comments) ) {
      console.log("empty fields");
      this.FailedBox('Please Enter Name');
      this.showLoader = false;
    }
    else {
      this.showLoader = true;
      //console.log("In else");
      //return this.showLoader;
      this._database.updateStatusByUser(Name, lead_comments,lead_id).then(data => {
        console.log("data print>>>>>>>>>>>>>>>",data);
        //return;
        if (data['success']) {
          this.SuccessBox('Inserted ..')
          this.ngOnInit();
          this.showLoader = false;
          this.router.navigate(['customers-list']);
          //form.resetForm();
          //this.input.nativeElement.value = null;
        }
        else {
          this.FailedBox(data['message'])
          this.showLoader = false;
        }
      }).catch(err => {
        this.showLoader = false;
      });
    }
  }
}
