import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatabaseService } from 'src/app/service/database.service';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    type: string;
}

const userData = JSON.parse(localStorage.getItem('dataUser'));
//console.log("userData inside sider bar component>>>>>>>",userData);
//console.log(userData['Role'][0]);


export const ROUTES: RouteInfo[] = [
    // { path: '/dashboard', title: 'Dashboard',  icon: 'ni-tv-2 text-primary', class: '' },
    // { path: '/userlist', title: 'Users List',  icon: 'ni-circle-08 text-blue', class: '' },
    { path: '/customers-list', title: 'Customers List',  icon: 'ni-circle-08 text-blue', class: '', type: 'all' },
    { path: '/customers-list', title: 'Customers List',  icon: 'ni-circle-08 text-blue', class: '', type: 'admin' },
    { path: '/userlist', title: 'Users',  icon: 'ni-circle-08 text-blue', class: '', type: 'admin'},
    { path: '/assigned-leads', title: 'Assigned Leads',  icon: 'ni-circle-08 text-blue', class: '', type: 'all'},
    // { path: '/category', title: 'Category',  icon: 'ni-key-25 text-blue', class: '' },
    // { path: '/places', title: 'Place',  icon: 'ni-app text-blue', class: '' },

];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;

  constructor(private router: Router,private _database : DatabaseService) { }

  ngOnInit() {
    const userData = JSON.parse(localStorage.getItem('dataUser'));
    console.log(userData);

    var isAdmin = "";
    if(userData != null && userData['Role'][0] == "ROLE_ADMIN"){
        console.log("This is admin user");
       //this.menuItems = ROUTES.filter(menuItem => menuItem);
       this.menuItems = ROUTES.filter(menuItem => {
        console.log(menuItem);
        if(menuItem['type']== "admin"){
          return menuItem;
        }
      });
    } else {
      console.log("This is normal user");
      this.menuItems = ROUTES.filter(menuItem => {
        console.log(menuItem);
        if(menuItem['type']== "all"){
          return menuItem;
        }
      });
    }
    
    // this.menuItems = ROUTES.filter(menuItem => menuItem);
    
    //console.log(this.menuItems);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }

  Logout(){
    this._database.logout();
    this.router.navigate(['login'])
  }
}
