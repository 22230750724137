import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs'

interface users {
  username: string,
  password: string
}

@Injectable({
  providedIn: 'root'
})

export class DatabaseService {

  constructor(private httpClient: HttpClient) {
    this.Startfunc();
  }
  private currentUserSubject: BehaviorSubject<users>;
  public previousUrl: any = "";

  //private link : any = "https://europe-west1-reactnativefirebase-2c017.cloudfunctions.net/api";
  //private link : any = "https://localhost:8080/api";
  private link : any = "https://leadapi.hashonedigital.com/api";
  public get currentUserValue(): users {
    if (this.currentUserSubject) {
      return this.currentUserSubject.value;
    }
    else {
      return
    }
  }

  Startfunc() {
    var data = "";
    //console.log("I am in start");
    try {
      data = JSON.parse(localStorage.getItem('dataUser'))
      //const ddd = localStorage.getItem('dataUser')
      //console.log("in start try block dddd>>>",ddd);
      //console.log("in start try block data>>>",data);

      if (data['success']) {
        //console.log("Inside Start Func if success block");
        //console.log(data);
        if (data['accessToken'] !== undefined && data['accessToken'] !== null && data['accessToken'] !== '') {
          //console.log("Inside Start Func if email  block");
          this.currentUserSubject = new BehaviorSubject<users>(JSON.parse(localStorage.getItem('dataUser')));
        }
      }
    } catch (error) {
      localStorage.removeItem('dataUser');
      this.currentUserSubject = new BehaviorSubject<users>(null);
    }
  }

  async checkAndUpdateLeadViewStatus(lead_id){
    const data = JSON.parse(localStorage.getItem('dataUser'))
    let dd = {lead_id:lead_id};
    console.log("I am inside checkAndUpdateLeadViewStatus",dd);
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': data['accessToken']
      }),
    };
    //console.log(data);
    //console.log(dd);
   return await this.httpClient.post(`${this.link}/checkAndUpdateLeadViewStatus`,dd,httpOptions).toPromise();

  }

  async getNotification(){
    const data = JSON.parse(localStorage.getItem('dataUser'))
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': data['accessToken']
      }),
    };

    return await this.httpClient.get(`${this.link}/getNotification`,httpOptions).toPromise();

  }
  async getUsers() {
    const data = JSON.parse(localStorage.getItem('dataUser'))
    //console.log(data);
    console.log("I have executed getUsers function");
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': data['accessToken']
      })
    };

    //console.log(httpOptions);
    //this.http.get(url, header)
    //return await this.httpClient.get(`${this.link}/customers/all`).toPromise();
    //let dd =  await this.httpClient.get(`${this.link}/customers/all`,httpOptions).toPromise();
    //console.log(dd);
    if(data['Role'][0] == 'ROLE_ADMIN'){
      return await this.httpClient.get(`${this.link}/customers/admin_all`,httpOptions).toPromise();
    } else {
      return await this.httpClient.get(`${this.link}/customers/all`,httpOptions).toPromise();
    }
    
  }

  async getAllMyLeads() {
    const data = JSON.parse(localStorage.getItem('dataUser'))
    console.log("I have executed getAllMyLeads function");
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': data['accessToken']
      })
    };

    return await this.httpClient.get(`${this.link}/getAllMyLeads`,httpOptions).toPromise();    
  }
  
  async getAllUsers() {
    const data = JSON.parse(localStorage.getItem('dataUser'))
    console.log("I have executed getAllUsers function");
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': data['accessToken']
      })
    };

    return await this.httpClient.get(`${this.link}/getAllUsers`,httpOptions).toPromise();
    //console.log(httpOptions);
    //this.http.get(url, header)
    //return await this.httpClient.get(`${this.link}/customers/all`).toPromise();
    //let dd =  await this.httpClient.get(`${this.link}/customers/all`,httpOptions).toPromise();
    //console.log(dd);


    // if(data['Role'][0] == 'ROLE_ADMIN'){
    //   return await this.httpClient.get(`${this.link}/customers/admin_all`,httpOptions).toPromise();
    // } else {
    //   return await this.httpClient.get(`${this.link}/customers/all`,httpOptions).toPromise();
    // }
    
  }

  async dashboardLogin(email, password) {
    console.log("inside dashboard login");
    const dataResponse = {};
    const data = await this.httpClient.post(`${this.link}/auth/signin`, { email, password }).toPromise().then(function (response) {
      console.log("I am in then block");
      console.log("response>>>>",response);
      dataResponse['success'] = true;
      dataResponse['error'] = false;
      dataResponse['status'] = 200;
      dataResponse['Role'] = response['roles'];
      dataResponse['accessToken'] = response['accessToken'];
      //localStorage.setItem('dataUser', JSON.stringify(dataResponse));

    }).catch(err=> {
      //console.log(err);
      //console.log("I am in catch block");
      //localStorage.removeItem('dataUser');
      dataResponse['success'] = false;
      dataResponse['error'] = true;
      dataResponse['message'] = err.error.message;
      dataResponse['status'] = err.status;
    });
    

    //console.log(dataResponse);

     if (dataResponse['success'] === true) {
       localStorage.setItem('dataUser', JSON.stringify(dataResponse));
      // const data = JSON.parse(localStorage.getItem('dataUser'))
      console.log(dataResponse);
       this.Startfunc();
       return dataResponse;
     } else {
       //console.log("in else block");
       //console.log(dataResponse);
       return dataResponse;
    }
    // return dataResponse;
  }

  logout() {
    localStorage.removeItem('dataUser');
    this.currentUserSubject = new BehaviorSubject<users>(null);
    this.currentUserSubject.next(null);
    return "";
  }

//get single lead detail
  async getLeadDetail(id){
    const data = JSON.parse(localStorage.getItem('dataUser'))
    // let params = new URLSearchParams();
    // params.append("id", id)
    let dd = {id: id};
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': data['accessToken']
      }),
      params: dd
    };

    console.log("I am inside getLeadDetail",id);
    return await this.httpClient.get(`${this.link}/customers/getLeadDetailsById`,httpOptions,).toPromise();
  }

  //#region CATEGORY
  async getCategory (){
    return await this.httpClient.get(`${this.link}/getcategory`).toPromise();
  }

  async InsertCategory(title,image){
    return await this.httpClient.post(`${this.link}/category`,{title,image}).toPromise();
  }

  async updateStatusByUser(lead_status,lead_comment,lead_id){
    const data = JSON.parse(localStorage.getItem('dataUser'))
    let dd = {lead_status: lead_status,lead_comment:lead_comment,lead_id:lead_id};
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': data['accessToken']
      }),
    };
    console.log(data);
    console.log(dd);
    return await this.httpClient.post(`${this.link}/customers/customerStatusUpdate`,dd,httpOptions).toPromise();
  }

  async UpdateCategory(id,title,image,status){
    return await this.httpClient.post(`${this.link}/category/${id}`,{title,image,status}).toPromise();
  }

  async getPlacebyId(id){
    return await this.httpClient.get(`${this.link}/getplaces/${id}`).toPromise();
  }
  //#endregion

  //#region CATEGORY
  async getPlace(){
    return await this.httpClient.get(`${this.link}/places`).toPromise();
  }

  async InsertPlace(title,category,urlbutton,description,image){
    return await this.httpClient.post(`${this.link}/places`,{title,category,urlbutton,description,image}).toPromise();
  }

  async UpdatePlace(id,title,category,urlbutton,description,image){
    return await this.httpClient.post(`${this.link}/places/${id}`,{id,title,category,urlbutton,description,image}).toPromise();
  }
  //#endregion

  //#region Rating
  async getRating(id){
    return await this.httpClient.get(`${this.link}/rating/${id}`).toPromise();
  }

  //#endregion
}
